import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "changelog"
    }}>{`Changelog`}</h1>
    <p>{`All notable changes to this project will be documented in this file. See `}<a parentName="p" {...{
        "href": "https://github.com/conventional-changelog/standard-version"
      }}>{`standard-version`}</a>{` for commit guidelines.`}</p>
    <h3 {...{
      "id": "0353-2021-04-30"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.35.2...v0.35.3"
      }}>{`0.35.3`}</a>{` (2021-04-30)`}</h3>
    <h3 {...{
      "id": "bug-fixes"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`disable warning about loose private methods (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/a2daa1d76f2ff4013ee49000fbcd4efa446de9a0"
        }}>{`a2daa1d`}</a>{`)`}</li>
      <li parentName="ul">{`don't pass images loaded with ?url to image-webpack-loader (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/ce345f14c85b1f667fc8fb555b6a372f230af398"
        }}>{`ce345f1`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0352-2021-04-27"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.35.1...v0.35.2"
      }}>{`0.35.2`}</a>{` (2021-04-27)`}</h3>
    <h3 {...{
      "id": "features"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`allow loading any file with 'import' as url or raw by adding ?raw or ?url (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/b82d22d43912c5d74e117159ff5687a56d348ed1"
        }}>{`b82d22d`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0351-2021-04-19"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.35.0...v0.35.1"
      }}>{`0.35.1`}</a>{` (2021-04-19)`}</h3>
    <h3 {...{
      "id": "features-1"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update asset loading & allow loading .md files (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/431877b24f161ff4ae59290ac5a1e2b51e08228a"
        }}>{`431877b`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0350-2021-03-08"
    }}><a parentName="h2" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.34.9...v0.35.0"
      }}>{`0.35.0`}</a>{` (2021-03-08)`}</h2>
    <h3 {...{
      "id": "-breaking-changes"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`Due to moving away from babel-plugin-react-intl-auto, using strings in defineMessages instead of an object is not supported. You can add babel-plugin-react-intl-auto back to your local babel config to restore previous behavior.`}</li>
    </ul>
    <h3 {...{
      "id": "features-2"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`disable hot reloading until issues have been resolved (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/527d4b1290ac6492c24a00632f2a22f3b765f5a7"
        }}>{`527d4b1`}</a>{`)`}</li>
      <li parentName="ul">{`replace babel-plugin-react-intl-auto with babel-plugin-formatjs (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/64ff6504a1af1189d56bdfcbadbdd7b35e3f6f71"
        }}>{`64ff650`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "bug-fixes-1"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`return to style-loader during dev & disable sri (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/fad902a90186501b0556bdd80e5e27a23939547c"
        }}>{`fad902a`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0349-2021-02-02"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.34.8...v0.34.9"
      }}>{`0.34.9`}</a>{` (2021-02-02)`}</h3>
    <h3 {...{
      "id": "features-3"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`mute InjectManifest in watch (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/4dda53ce3b224f6ca8fb8c1b2edca7873924d1e4"
        }}>{`4dda53c`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0348-2021-01-19"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.34.7...v0.34.8"
      }}>{`0.34.8`}</a>{` (2021-01-19)`}</h3>
    <h3 {...{
      "id": "bug-fixes-2"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`revert service worker file name so old versions can still update (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/679d3dbcc84ab4c71486a560a44ec3d7bcaf43de"
        }}>{`679d3db`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0347-2021-01-19"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.34.6...v0.34.7"
      }}>{`0.34.7`}</a>{` (2021-01-19)`}</h3>
    <h3 {...{
      "id": "bug-fixes-3"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`don't register serviceWorker if not supported (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/763f3ca51ea00e3970160742cda70cc2a5aa5b0f"
        }}>{`763f3ca`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0346-2021-01-13"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.34.5...v0.34.6"
      }}>{`0.34.6`}</a>{` (2021-01-13)`}</h3>
    <h3 {...{
      "id": "features-4"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`disable warning spam from mini-css-extract-plugin (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/df68928122941897fd6d2550cb1b8c11258d6c6c"
        }}>{`df68928`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0345-2021-01-08"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.34.0...v0.34.5"
      }}>{`0.34.5`}</a>{` (2021-01-08)`}</h3>
    <h3 {...{
      "id": "features-5"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`disable workbox cache in development (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/39433c9f44f499501d35fa28400200ddacf3c512"
        }}>{`39433c9`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "bug-fixes-4"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`copy dotfiles from /public (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/9a42454b0707e900d848e7234ccd7aa413ff69a6"
        }}>{`9a42454`}</a>{`)`}</li>
      <li parentName="ul">{`prevent watch mode crash when accessing path other than / (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/c1f0f5cb9b273f211d171b858215304eb0b0ec16"
        }}>{`c1f0f5c`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0340-2021-01-05"
    }}><a parentName="h2" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.33.3...v0.34.0"
      }}>{`0.34.0`}</a>{` (2021-01-05)`}</h2>
    <h3 {...{
      "id": "features-6"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add new CSS class ident generator (dev) (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/51317bacc006ee878430a090e392b8f502496cb4"
        }}>{`51317ba`}</a>{`)`}</li>
      <li parentName="ul">{`replace offline-plugin with workbox (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/f88f8642f674f5ec7a46ba91dbe2ce3dc0310fe9"
        }}>{`f88f864`}</a>{`)`}</li>
      <li parentName="ul">{`update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/261915706ed63005d6e2de597b0f5f5b42e48055"
        }}>{`2619157`}</a>{`)`}</li>
      <li parentName="ul">{`update to webpack 5 (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/93a384aa610ce8abf450fd7ad9a81a0b1e9ddfcb"
        }}>{`93a384a`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0333-2020-11-09"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.33.2...v0.33.3"
      }}>{`0.33.3`}</a>{` (2020-11-09)`}</h3>
    <h3 {...{
      "id": "features-7"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add option to disable subresource integrity (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/5b74257ec1e192d89b20cc408b9195e755988ca4"
        }}>{`5b74257`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0332-2020-08-31"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.33.1...v0.33.2"
      }}>{`0.33.2`}</a>{` (2020-08-31)`}</h3>
    <h3 {...{
      "id": "-breaking-changes-1"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`server-side rendering requires node 12+`}</li>
    </ul>
    <h3 {...{
      "id": "features-8"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`html - add integrity hashes in production (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/adaea1e04f70842c97e41fa5c5144cede95931cf"
        }}>{`adaea1e`}</a>{`)`}</li>
      <li parentName="ul">{`rjs init - print message hinting at next step (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/1d4faa7075bcbaab101c1269b099af13cf5f42bc"
        }}>{`1d4faa7`}</a>{`)`}</li>
      <li parentName="ul">{`update default lintstaged to include ts & remove git add (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/784eec8212269b97d025504472c0289b7c9ca6da"
        }}>{`784eec8`}</a>{`)`}</li>
      <li parentName="ul">{`update intl polyfills (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/2229ac7599c38eb96a4ae0031136a17a6e6636e3"
        }}>{`2229ac7`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "bug-fixes-5"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix how intl polyfills are imported (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/33ac3cb175d902f5b7080ef41a8a1ba735122c2a"
        }}>{`33ac3cb`}</a>{`)`}</li>
      <li parentName="ul">{`send error page if initial build failed instead of crashing (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/da6af5b2c0e410ad6ba1a3391054186eff2db1ba"
        }}>{`da6af5b`}</a>{`)`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`update hapi/accept (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/c8410a374da502c1a722fcb4f80b222130d915d1"
        }}>{`c8410a3`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0331-2020-08-05"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.33.0...v0.33.1"
      }}>{`0.33.1`}</a>{` (2020-08-05)`}</h3>
    <h3 {...{
      "id": "features-9"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`replace react-hot with react-refresh 🎉 (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/b2f1b5f2897bf43b82910f880b90669ab4f94bc5"
        }}>{`b2f1b5f`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0330-2020-07-16"
    }}><a parentName="h2" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.32.2...v0.33.0"
      }}>{`0.33.0`}</a>{` (2020-07-16)`}</h2>
    <h3 {...{
      "id": "-breaking-changes-2"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`sass is now using dart-sass`}</li>
    </ul>
    <h3 {...{
      "id": "features-10"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/171a5c05e9935236507a6b767e002858862d63c1"
        }}>{`171a5c0`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "bug-fixes-6"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`install sass instead of dart-sass (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/4eb97ab6ad6751493d4a964a422652f6de37fa30"
        }}>{`4eb97ab`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0322-2020-04-23"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.32.1...v0.32.2"
      }}>{`0.32.2`}</a>{` (2020-04-23)`}</h3>
    <h3 {...{
      "id": "bug-fixes-7"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix intl-plural-rules polyfill not loading locales (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/446177b53dd1e52bd8b3ad931f9fc62caa047ac3"
        }}>{`446177b`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0321-2020-04-23"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.32.0...v0.32.1"
      }}>{`0.32.1`}</a>{` (2020-04-23)`}</h3>
    <h3 {...{
      "id": "bug-fixes-8"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`make globalThis ponyfill work in safari (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/f3f685442817b3ec4d89d8d7498d98a5883382ab"
        }}>{`f3f6854`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0320-2020-04-15"
    }}><a parentName="h2" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.31.1...v0.32.0"
      }}>{`0.32.0`}</a>{` (2020-04-15)`}</h2>
    <h3 {...{
      "id": "-breaking-changes-3"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`react-intl has been upgraded to v4, you might have to update your messages`}</li>
    </ul>
    <h3 {...{
      "id": "features-11"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add dev 404 route (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/a07a742eef0a8aacdbdb4b6b852c222e1bd10084"
        }}>{`a07a742`}</a>{`)`}</li>
      <li parentName="ul">{`include wrapped comp name in withContext name (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/91e2e1304cade4116863c799f6aaef17f58737d9"
        }}>{`91e2e13`}</a>{`)`}</li>
      <li parentName="ul">{`load ts route files by default (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/ada189fbb6724be7ef1963a73b8ae08ed45cb6e5"
        }}>{`ada189f`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "bug-fixes-9"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix how dev 404 route is loaded (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/14577946991cced69e115fb946b451d9d8b9a8ce"
        }}>{`1457794`}</a>{`)`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/d8ed1aaf5837d670205efc6f2de577c9a0bd08e9"
        }}>{`d8ed1aa`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0311-2020-03-18"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.31.0...v0.31.1"
      }}>{`0.31.1`}</a>{` (2020-03-18)`}</h3>
    <h3 {...{
      "id": "features-12"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`css classes are now minified instead of hashed in production (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/b82c9c4e672c634dc16edd55388ace6ba7728ef1"
        }}>{`b82c9c4`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0310-2020-03-12"
    }}><a parentName="h2" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.30.0...v0.31.0"
      }}>{`0.31.0`}</a>{` (2020-03-12)`}</h2>
    <h3 {...{
      "id": "-breaking-changes-4"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`Framework now requires a minimum of Safari 10.3, as the base "Intl" object is no longer polyfilled`}</li>
    </ul>
    <h3 {...{
      "id": "features-13"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`drop `}<inlineCode parentName="li">{`Intl`}</inlineCode>{` polyfill, polyfill ListFormat & Unit Number Format (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/344742e8a8e04332ab0f8d30cb7cbc1134d0b360"
        }}>{`344742e`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0300-2020-02-19"
    }}><a parentName="h2" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.29.5...v0.30.0"
      }}>{`0.30.0`}</a>{` (2020-02-19)`}</h2>
    <h3 {...{
      "id": "features-14"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`replace HashHistory with BrowserHistory with hash prefix (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/7be8b00cd2401fec005c015c82d8687d5e7bcf00"
        }}>{`7be8b00`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "bug-fixes-10"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`attempt correcting location in hash routing (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/3c8e7662792fff66e869591e21c8bccaec9715fb"
        }}>{`3c8e766`}</a>{`)`}</li>
      <li parentName="ul">{`hash - fix hash router breaking if there is a search query (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/41ad219656e9173d22dc56856b61b11464c7a97c"
        }}>{`41ad219`}</a>{`)`}</li>
      <li parentName="ul">{`hash routing - clean url through `}<inlineCode parentName="li">{`history`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/e35a9a67e26c47ed20bbf67f39d645860c4e2277"
        }}>{`e35a9a6`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0295-2020-02-10"
    }}><a parentName="h3" {...{
        "href": "https://github.com/reworkjs/reworkjs/compare/v0.29.4...v0.29.5"
      }}>{`0.29.5`}</a>{` (2020-02-10)`}</h3>
    <h3 {...{
      "id": "features-15"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`replace style-loader with extract-css-plugin in development (`}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/reworkjs/commit/6009bcd24288b94b2d730ef99e5a86946cbdb6bc"
        }}>{`6009bcd`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0294-2020-01-20"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.29.3...v0.29.4"
      }}>{`0.29.4`}</a>{` (2020-01-20)`}</h3>
    <h3 {...{
      "id": "features-16"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`expose API to know if a new SW has been installed (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c5b47c549694680d3885e681ba60eb746749425f"
        }}>{`c5b47c5`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0293-2019-12-03"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.29.2...v0.29.3"
      }}>{`0.29.3`}</a>{` (2019-12-03)`}</h3>
    <h3 {...{
      "id": "bug-fixes-11"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`properly dispatch service-worker lifecycle events (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/8a9171265a258fb6f1d9317e42031f063fcbe9bc"
        }}>{`8a91712`}</a>{`)`}</li>
      <li parentName="ul">{`replace intl-pluralrules with formatjs as the former is buggy (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/dae6b406adcb3e12199b06791bd6c29b18d947ff"
        }}>{`dae6b40`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0292-2019-11-20"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.29.1...v0.29.2"
      }}>{`0.29.2`}</a>{` (2019-11-20)`}</h3>
    <h3 {...{
      "id": "bug-fixes-12"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix helmet data being discarded in ssr (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/906a4dc3eac48754e4796acc8495ad12dd852191"
        }}>{`906a4dc`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0291-2019-11-20"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.29.0...v0.29.1"
      }}>{`0.29.1`}</a>{` (2019-11-20)`}</h3>
    <h3 {...{
      "id": "features-17"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add new getPluginInstance API (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/22db85e33a1c557f1a17a4857e9bd59db0633d97"
        }}>{`22db85e`}</a>{`)`}</li>
      <li parentName="ul">{`expose service worker events (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/28ad2fd9342db7f482bb5ae6067ea0d360bba5d7"
        }}>{`28ad2fd`}</a>{`)`}</li>
      <li parentName="ul">{`reduce logging spam, add webpackbar (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/b697d12a9b064c71678d7da2ce1458a4f5fe1c7c"
        }}>{`b697d12`}</a>{`)`}</li>
      <li parentName="ul">{`replace react-helmet with react-helmet-async (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/161bee12c7fe8203c6c751b9dd9c9b75c8f4218a"
        }}>{`161bee1`}</a>{`)`}</li>
      <li parentName="ul">{`update react-hot-loader (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/9839f2e200964e6fefa3aeb059a151c2fdd5b8a3"
        }}>{`9839f2e`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0290-2019-11-16"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.28.1...v0.29.0"
      }}>{`0.29.0`}</a>{` (2019-11-16)`}</h2>
    <h3 {...{
      "id": "-breaking-changes-5"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`usage of `}<inlineCode parentName="li">{`--prerendering`}</inlineCode>{` flag must be changed to `}<inlineCode parentName="li">{`--ssr`}</inlineCode></li>
      <li parentName="ul">{`you will now need either move the files to a subfolder or adapt references to public resources`}</li>
    </ul>
    <h3 {...{
      "id": "features-18"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`allow js webpack type to be extended by plugins (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/6af969838bc381981365f27f89e7f4f52e928def"
        }}>{`6af9698`}</a>{`)`}</li>
      <li parentName="ul">{`always purge unused react-intl polyfills & add relativetime (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/9c226bc93060580d3d0fa5eb415a9fc529b29855"
        }}>{`9c226bc`}</a>{`)`}</li>
      <li parentName="ul">{`copy files from resource directory to root of dist (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/6cab5b75d629fe792fb063877dc089924182557f"
        }}>{`6cab5b7`}</a>{`)`}</li>
      <li parentName="ul">{`default lint-staged now autofixes (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/d0fd5087cfa779a6065f31753087f41b72948312"
        }}>{`d0fd508`}</a>{`)`}</li>
      <li parentName="ul">{`expose APIs to access react-router context & set http status (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/f1542145bb85eaad1ba7eb33da026a05bb671bd0"
        }}>{`f154214`}</a>{`)`}</li>
      <li parentName="ul">{`parse typescript as javascript files (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/78ef8b99811233e3b0ca820720e1d20e6d4a274e"
        }}>{`78ef8b9`}</a>{`)`}</li>
      <li parentName="ul">{`rename prerendering -> ssr (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/7ead843d02297f495885484d8ee4b016e4752b31"
        }}>{`7ead843`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "bug-fixes-13"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`accept extra args in build (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/e78e0f85b69b94dbb0fc2399df71d2e613d8841f"
        }}>{`e78e0f8`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0281-2019-11-08"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.28.0...v0.28.1"
      }}>{`0.28.1`}</a>{` (2019-11-08)`}</h3>
    <h3 {...{
      "id": "bug-fixes-14"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix husky init crash (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/3cc6744850abfc2a5d0f9e7e84e33d447743efca"
        }}>{`3cc6744`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0280-2019-11-08"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.27.0...v0.28.0"
      }}>{`0.28.0`}</a>{` (2019-11-08)`}</h2>
    <h3 {...{
      "id": "features-19"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`improve & update rjs init (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/0474666610bf7124c2b5ec0936135f563c301cba"
        }}>{`0474666`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "bug-fixes-15"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix logs being persisted to "undefined" dir (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/9cae513dfde37e1fb46c1f10a1ce0e9f88e13d0e"
        }}>{`9cae513`}</a>{`)`}</li>
      <li parentName="ul">{`require proper version of react-dom (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c0edf3fe56b06ccedbca09bf8a6fbf3ad1ba9b6c"
        }}>{`c0edf3f`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0270-2019-10-24"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.26.0...v0.27.0"
      }}>{`0.27.0`}</a>{` (2019-10-24)`}</h2>
    <h3 {...{
      "id": "features-20"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`drop srcset-loader as it is dead (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/ab4e1ea8cb94a814a3225bb3533197727c0786cd"
        }}>{`ab4e1ea`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-breaking-changes-6"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`Any usage of srcset-loader will need to be updated`}</li>
    </ul>
    <h2 {...{
      "id": "0260-2019-08-06"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.25.2...v0.26.0"
      }}>{`0.26.0`}</a>{` (2019-08-06)`}</h2>
    <h3 {...{
      "id": "features-21"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update react-intl to 3.x.x & remove react-intl workaround (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/d870528"
        }}>{`d870528`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0252-2019-08-02"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.25.1...v0.25.2"
      }}>{`0.25.2`}</a>{` (2019-08-02)`}</h3>
    <h3 {...{
      "id": "bug-fixes-16"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`remove `}<a parentName="li" {...{
          "href": "https://github.com/reworkjs/redux"
        }}>{`@reworkjs/redux`}</a>{` from externals (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/08e5f40"
        }}>{`08e5f40`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0251-2019-07-12"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.25.0...v0.25.1"
      }}>{`0.25.1`}</a>{` (2019-07-12)`}</h3>
    <h3 {...{
      "id": "bug-fixes-17"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`force app refresh after intl change (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/24c15f2"
        }}>{`24c15f2`}</a>{`)`}</li>
      <li parentName="ul">{`minify css in production (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c298003"
        }}>{`c298003`}</a>{`)`}</li>
      <li parentName="ul">{`resolve webpack deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/29f1908"
        }}>{`29f1908`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-22"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`enable "removeAvailableModules" webpack opti (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/31b30b2"
        }}>{`31b30b2`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0250-2019-07-09"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.24.1...v0.25.0"
      }}>{`0.25.0`}</a>{` (2019-07-09)`}</h2>
    <h3 {...{
      "id": "bug-fixes-18"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`create new Chalk instance using Chalk.constructor (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/4815551"
        }}>{`4815551`}</a>{`)`}</li>
      <li parentName="ul">{`fix init ignoring errors (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/5e2f06b"
        }}>{`5e2f06b`}</a>{`)`}</li>
      <li parentName="ul">{`lazy load server-hooks (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/30036ce"
        }}>{`30036ce`}</a>{`)`}</li>
      <li parentName="ul">{`make babel cache env & side aware (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/b5e1b3e"
        }}>{`b5e1b3e`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0241-2019-07-03"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.24.0...v0.24.1"
      }}>{`0.24.1`}</a>{` (2019-07-03)`}</h3>
    <h3 {...{
      "id": "bug-fixes-19"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix useLocation having undefined in its url (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/9084cd5"
        }}>{`9084cd5`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0240-2019-06-18"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.23.0...v0.24.0"
      }}>{`0.24.0`}</a>{` (2019-06-18)`}</h2>
    <h3 {...{
      "id": "bug-fixes-20"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`resolve adhoc hook based on config file `}<em parentName="li">{`directory`}</em>{` (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/4bd941a"
        }}>{`4bd941a`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-23"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`allow providing ad-hoc hooks (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/8cb50bc"
        }}>{`8cb50bc`}</a>{`)`}</li>
      <li parentName="ul">{`provide hook to configure express (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/a66100c"
        }}>{`a66100c`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0230-2019-06-14"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.22.0...v0.23.0"
      }}>{`0.23.0`}</a>{` (2019-06-14)`}</h2>
    <h3 {...{
      "id": "bug-fixes-21"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`don't inject babel-runtime inside of core-js (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/833b3c7"
        }}>{`833b3c7`}</a>{`)`}</li>
      <li parentName="ul">{`replace WebpackClean with CleanWebpack (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/d9021f5"
        }}>{`d9021f5`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/issues/55"
        }}>{`#55`}</a></li>
    </ul>
    <h3 {...{
      "id": "features-24"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add config option to use the react-router HashRouter (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/840a185"
        }}>{`840a185`}</a>{`)`}</li>
      <li parentName="ul">{`allow people to define a custom path to the configuration file (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/5f5b98f"
        }}>{`5f5b98f`}</a>{`)`}</li>
      <li parentName="ul">{`validate configuration using Joi (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/9eb9c65"
        }}>{`9eb9c65`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-breaking-changes-7"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`default config file is now `}<inlineCode parentName="li">{`<project_dir>/.reworkrc`}</inlineCode></li>
      <li parentName="ul">{`relative files & directories specified in config file are now relative to folder containing the config file.`}</li>
    </ul>
    <h2 {...{
      "id": "0220-2019-05-28"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.21.0...v0.22.0"
      }}>{`0.22.0`}</a>{` (2019-05-28)`}</h2>
    <h3 {...{
      "id": "bug-fixes-22"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`make exported hooks use non-ssr version by default (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/79ededf"
        }}>{`79ededf`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0210-2019-05-27"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.20.2...v0.21.0"
      }}>{`0.21.0`}</a>{` (2019-05-27)`}</h2>
    <h3 {...{
      "id": "bug-fixes-23"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`make server-side use /lib to avoid esm in modules (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/4f607e3"
        }}>{`4f607e3`}</a>{`)`}</li>
      <li parentName="ul">{`prevent dev SSR server from crashing if front is ready before back-end (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/ebed78c"
        }}>{`ebed78c`}</a>{`)`}</li>
      <li parentName="ul">{`prevent server from crashing in dev mode if an exception occurs during build (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/48a64c2"
        }}>{`48a64c2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-25"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add experimental SSR resource loading (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/bb34439"
        }}>{`bb34439`}</a>{`)`}</li>
      <li parentName="ul">{`add support for `}<a parentName="li" {...{
          "href": "https://github.com/loadable"
        }}>{`@loadable`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/6b2cbcb"
        }}>{`6b2cbcb`}</a>{`)`}</li>
      <li parentName="ul">{`add usePersistentValue (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/472813c"
        }}>{`472813c`}</a>{`)`}</li>
      <li parentName="ul">{`load .browser.ext over .ext on browser, .server.ext on server if present (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/422af5e"
        }}>{`422af5e`}</a>{`)`}</li>
      <li parentName="ul">{`make use-location return URL & cause re-render on change (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/40b23c8"
        }}>{`40b23c8`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0202-2019-05-13"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.20.1...v0.20.2"
      }}>{`0.20.2`}</a>{` (2019-05-13)`}</h3>
    <h3 {...{
      "id": "bug-fixes-24"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`prevent lodash feature from using babel.config.js when compiling node_modules (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c57cb87"
        }}>{`c57cb87`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0201-2019-05-07"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.20.0...v0.20.1"
      }}>{`0.20.1`}</a>{` (2019-05-07)`}</h3>
    <h3 {...{
      "id": "bug-fixes-25"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`disable using babel.config.js as we load it manually (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/b336ba0"
        }}>{`b336ba0`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0200-2019-04-26"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.19.0...v0.20.0"
      }}>{`0.20.0`}</a>{` (2019-04-26)`}</h2>
    <h3 {...{
      "id": "bug-fixes-26"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`don't parse node_modules with remove-prop-types (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/9ce4429"
        }}>{`9ce4429`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-26"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add ssr ready use-dnt, use-user-agent, use-location (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/5c808c2"
        }}>{`5c808c2`}</a>{`)`}</li>
      <li parentName="ul">{`expose request and response through context when in SSR (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/8225d26"
        }}>{`8225d26`}</a>{`)`}</li>
      <li parentName="ul">{`remove compression (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/8a76d19"
        }}>{`8a76d19`}</a>{`)`}</li>
      <li parentName="ul">{`replace uglify with terser & update deps (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/1537111"
        }}>{`1537111`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0190-2019-04-23"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.18.0...v0.19.0"
      }}>{`0.19.0`}</a>{` (2019-04-23)`}</h2>
    <h3 {...{
      "id": "features-27"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`replace with-context-consumer with with-context (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/59600f1"
        }}>{`59600f1`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0180-2019-04-23"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.17.4...v0.18.0"
      }}>{`0.18.0`}</a>{` (2019-04-23)`}</h2>
    <h3 {...{
      "id": "bug-fixes-27"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`babel presets - use NODE_ENV if BABEL_ENV is not set (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/2198063"
        }}>{`2198063`}</a>{`)`}</li>
      <li parentName="ul">{`cancel redirect if same url, fix redirect crashing (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/b60b536"
        }}>{`b60b536`}</a>{`)`}</li>
      <li parentName="ul">{`make "server" build use /lib instead of /es (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/adae823"
        }}>{`adae823`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-28"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`remove "webpack" from build folder name (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/33d4c28"
        }}>{`33d4c28`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0174-2019-03-05"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.17.3...v0.17.4"
      }}>{`0.17.4`}</a>{` (2019-03-05)`}</h3>
    <h3 {...{
      "id": "bug-fixes-28"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`don't resolve main-component so webpack is the one resolving files (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/04a33c1"
        }}>{`04a33c1`}</a>{`)`}</li>
      <li parentName="ul">{`remove .react.js from special file extension (.js is enough) (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/798d10a"
        }}>{`798d10a`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0173-2019-02-22"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.17.2...v0.17.3"
      }}>{`0.17.3`}</a>{` (2019-02-22)`}</h3>
    <h3 {...{
      "id": "bug-fixes-29"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`update dependencies & remove explicit regeneratorRuntime (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/b40e61f"
        }}>{`b40e61f`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0172-2019-02-12"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.17.1...v0.17.2"
      }}>{`0.17.2`}</a>{` (2019-02-12)`}</h3>
    <h3 {...{
      "id": "bug-fixes-30"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`stringify argv so webpack can replace it in built files (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/29dae67"
        }}>{`29dae67`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0171-2019-02-12"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.17.0...v0.17.1"
      }}>{`0.17.1`}</a>{` (2019-02-12)`}</h3>
    <h3 {...{
      "id": "bug-fixes-31"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`make @reworkjs/core/argv work on node processes (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/018cfc1"
        }}>{`018cfc1`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0170-2019-02-11"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.16.0...v0.17.0"
      }}>{`0.17.0`}</a>{` (2019-02-11)`}</h2>
    <h3 {...{
      "id": "bug-fixes-32"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`expose context for use in react hooks (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/a577078"
        }}>{`a577078`}</a>{`)`}</li>
      <li parentName="ul">{`update usage of old babel plugin (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/a2726ee"
        }}>{`a2726ee`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-29"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`remove eslint-loader (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/220423f"
        }}>{`220423f`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0160-2019-02-07"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.15.1...v0.16.0"
      }}>{`0.16.0`}</a>{` (2019-02-07)`}</h2>
    <h3 {...{
      "id": "bug-fixes-33"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`disable css-loader minimize, move to postcss (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c501eed"
        }}>{`c501eed`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-30"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`remove build-in ngrok support (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/3f05ca2"
        }}>{`3f05ca2`}</a>{`)`}</li>
      <li parentName="ul">{`remove intl locales not matching available translations (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/06e1f1a"
        }}>{`06e1f1a`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-breaking-changes-8"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`use ngrok externally`}</li>
    </ul>
    <h3 {...{
      "id": "0151-2019-01-08"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.15.0...v0.15.1"
      }}>{`0.15.1`}</a>{` (2019-01-08)`}</h3>
    <h3 {...{
      "id": "bug-fixes-34"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`config:`}</strong>{` fix setting react-entry crashing the app (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/bebefa4"
        }}>{`bebefa4`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0150-2019-01-08"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.14.1...v0.15.0"
      }}>{`0.15.0`}</a>{` (2019-01-08)`}</h2>
    <h3 {...{
      "id": "bug-fixes-35"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix crash when persisting locale in cookies (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/278abbb"
        }}>{`278abbb`}</a>{`)`}</li>
      <li parentName="ul">{`Store prefered locale on change (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/a348e8d"
        }}>{`a348e8d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`babel:`}</strong>{` do not run react preset on node_modules (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/b912832"
        }}>{`b912832`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`babel:`}</strong>{` fix crashes related to babel update (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/deca477"
        }}>{`deca477`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`locale:`}</strong>{` enable react-intl locale after it has been loaded (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/5ef4818"
        }}>{`5ef4818`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`react-intl:`}</strong>{` create locale alias when framework finds one that does not exist in react-intl (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/0f1e5c8"
        }}>{`0f1e5c8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`route:`}</strong>{` add key to top level routes (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/2d7e693"
        }}>{`2d7e693`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-31"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`allow plugins to hook client and server rendering (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/d6c2eb0"
        }}>{`d6c2eb0`}</a>{`)`}</li>
      <li parentName="ul">{`completely replace redux with new plugin system (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/1170ddf"
        }}>{`1170ddf`}</a>{`)`}</li>
      <li parentName="ul">{`expose singleton instance on plugins (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/5dc23ed"
        }}>{`5dc23ed`}</a>{`)`}</li>
      <li parentName="ul">{`hydrate react tree if its container has content, render otherwise (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/776ef82"
        }}>{`776ef82`}</a>{`)`}</li>
      <li parentName="ul">{`move public modules from index.js to individual sub-modules (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/05d241b"
        }}>{`05d241b`}</a>{`)`}</li>
      <li parentName="ul">{`Prevent ReworkJsWrapper from generating a new Div (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/20231ba"
        }}>{`20231ba`}</a>{`)`}</li>
      <li parentName="ul">{`Remove all usages of Redux (will add them through plugins) (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/2566067"
        }}>{`2566067`}</a>{`)`}</li>
      <li parentName="ul">{`update to react-router v4 (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/61d0860"
        }}>{`61d0860`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`babel:`}</strong>{` update to babel 7, update hot reload system, transpile node_modules (stable only) (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c489349"
        }}>{`c489349`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`i18n:`}</strong>{` make translations local to a single react tree (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/921dec8"
        }}>{`921dec8`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "0141-2018-08-09"
    }}><a parentName="h3" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.14.0...v0.14.1"
      }}>{`0.14.1`}</a>{` (2018-08-09)`}</h3>
    <h3 {...{
      "id": "bug-fixes-36"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`make get-translations node mock work on non-unix filesystems (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/a4424b3"
        }}>{`a4424b3`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0140-2018-06-12"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.13.0...v0.14.0"
      }}>{`0.14.0`}</a>{` (2018-06-12)`}</h2>
    <h3 {...{
      "id": "bug-fixes-37"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Fix crash when loading a project without an existing configuration file (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/48aaf40"
        }}>{`48aaf40`}</a>{`)`}</li>
      <li parentName="ul">{`Make render-html & pre-init optional (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/81c5cf2"
        }}>{`81c5cf2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-32"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add a way to append a script to the service worker (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/5baeb49"
        }}>{`5baeb49`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0130-2018-05-09"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.12.0...v0.13.0"
      }}>{`0.13.0`}</a>{` (2018-05-09)`}</h2>
    <h3 {...{
      "id": "bug-fixes-38"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Compress CSS files with gzip (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/572b59f"
        }}>{`572b59f`}</a>{`)`}</li>
      <li parentName="ul">{`Fix sending a corrupted response when trying to access a precompressed resource with a query parameter (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/0d9ca5d"
        }}>{`0d9ca5d`}</a>{`)`}</li>
      <li parentName="ul">{`Make SW cache root route (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/5c77e0e"
        }}>{`5c77e0e`}</a>{`)`}</li>
      <li parentName="ul">{`Make the built server able to run from a different directory than the one it was built in (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/4f1480c"
        }}>{`4f1480c`}</a>{`)`}</li>
      <li parentName="ul">{`only import framework-config in bundle in development mode (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/fc2c95e"
        }}>{`fc2c95e`}</a>{`)`}</li>
      <li parentName="ul">{`Use log directory to store app logs instead of build directory (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/cb6837d"
        }}>{`cb6837d`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-33"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add brotli pre-compress support (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c43844b"
        }}>{`c43844b`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0120-2018-04-18"
    }}><a parentName="h2" {...{
        "href": "https://github.com/foobarhq/reworkjs/compare/v0.11.0...v0.12.0"
      }}>{`0.12.0`}</a>{` (2018-04-18)`}</h2>
    <h3 {...{
      "id": "bug-fixes-39"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Disable removeAvailableModules in development (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c7ec6ee"
        }}>{`c7ec6ee`}</a>{`)`}</li>
      <li parentName="ul">{`Disable webp minification (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/2832d19"
        }}>{`2832d19`}</a>{`)`}</li>
      <li parentName="ul">{`Fix injecting cookies into BaseHelmet when building the app (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/faefd8d"
        }}>{`faefd8d`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-34"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Disable eslint by default (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/f1f9e4b"
        }}>{`f1f9e4b`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-breaking-changes-9"
    }}>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`Enable eslint using --features=eslint`}</li>
    </ul>
    <h2 {...{
      "id": "0110-2018-04-11"
    }}>{`0.11.0 (2018-04-11)`}</h2>
    <h3 {...{
      "id": "bug-fixes-40"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Bump required webpack-bundle-analyzer version to solve issue with concat modules (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/de8494a"
        }}>{`de8494a`}</a>{`)`}</li>
      <li parentName="ul">{`Don't ss-render context style tags in production (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/d9f28b2"
        }}>{`d9f28b2`}</a>{`)`}</li>
      <li parentName="ul">{`Fix app builder crashing in watch mode when initial build failed and an user tries to access it (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/fa274bb"
        }}>{`fa274bb`}</a>{`)`}</li>
      <li parentName="ul">{`Fix react not being able to display errors due to cross-origin issues (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/49dfbcb"
        }}>{`49dfbcb`}</a>{`)`}</li>
      <li parentName="ul">{`Fix server-side loading of CSS (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/fd27864"
        }}>{`fd27864`}</a>{`)`}</li>
      <li parentName="ul">{`Launch pre-rendering server on correct port in prod (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/38003c3"
        }}>{`38003c3`}</a>{`)`}</li>
      <li parentName="ul">{`Make render-html config entry work with SSR (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/d9c5a73"
        }}>{`d9c5a73`}</a>{`)`}</li>
      <li parentName="ul">{`Migrate optimization plugins to webpack.optimization (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/bd8152d"
        }}>{`bd8152d`}</a>{`)`}</li>
      <li parentName="ul">{`Only pre-render CSS as `}<inlineCode parentName="li">{`<style>`}</inlineCode>{` tags in development (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/71ce71b"
        }}>{`71ce71b`}</a>{`)`}</li>
      <li parentName="ul">{`Only pre-serve named bundles for reliability (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/7d4f6ae"
        }}>{`7d4f6ae`}</a>{`)`}</li>
      <li parentName="ul">{`Optimize images on the server too so their hash is identical to the front-end (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/8a6af49"
        }}>{`8a6af49`}</a>{`)`}</li>
      <li parentName="ul">{`Prevent passing webp files to file-loader twice (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/c072796"
        }}>{`c072796`}</a>{`)`}</li>
      <li parentName="ul">{`Remove --no-prerendering warning as prerendering is now off by default (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/a605987"
        }}>{`a605987`}</a>{`)`}</li>
      <li parentName="ul">{`Replace deprecated webpack.optimize.UglifyJsPlugin with uglifyjs-webpack-plugin (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/f0eac80"
        }}>{`f0eac80`}</a>{`)`}</li>
      <li parentName="ul">{`Set lang html attribute to loaded language (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/f64c6b2"
        }}>{`f64c6b2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-35"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add support for loading .mjs files (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/2c80a58"
        }}>{`2c80a58`}</a>{`)`}</li>
      <li parentName="ul">{`Add support for webp (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/06843a5"
        }}>{`06843a5`}</a>{`)`}</li>
      <li parentName="ul">{`Don't generate chunk names in splitChunks for long term caching (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/fa82439"
        }}>{`fa82439`}</a>{`)`}</li>
      <li parentName="ul">{`CSS is now split per chunk (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/43554a3"
        }}>{`43554a3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`webpack:`}</strong>{` Pass WebP to imagemin, configure mozjpeg&gifsicle (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/1660ad3"
        }}>{`1660ad3`}</a>{`)`}</li>
      <li parentName="ul">{`Update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/a4265af"
        }}>{`a4265af`}</a>{`)`}</li>
      <li parentName="ul">{`Update peer dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/32646bb"
        }}>{`32646bb`}</a>{`)`}</li>
      <li parentName="ul">{`Migrate to React 16.3.1`}</li>
    </ul>
    <h3 {...{
      "id": "-breaking-changes-10"
    }}>{`⚠ Breaking Changes`}</h3>
    <ul>
      <li parentName="ul">{`Drop support for node `}{`<`}{` latest LTS (= 8) (`}<a parentName="li" {...{
          "href": "https://github.com/foobarhq/reworkjs/commit/80b76be"
        }}>{`80b76be`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      